/**
 * @namespace components
 */

import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';

import { miniGazelle, dataLayer } from '../../../helpers';

import './ChangeCountry.scss';
import ExpandCollapseButtons from '../../shared/ExpandCollapseButtons/ExpandCollapseButtons';
import _ from 'lodash';
import { clone, map } from 'lodash';

/**
 * @class ChangeCountry
 *
 * Module that lists all available region/country/language combinations to allow the user to change their selected country or language
 *
 * @memberof components
 *
 *
 * @property {string} name - Name of the component for the purposes of dataLayer events. Fixed and passed by the renderer
 * @property {object} data - Primary object to insert data props into component
 * @property {object} data.logicalName - Logical name of the module, used as the HTML node ID
 * @property {string} data.target - Return target for change country links that takes the user back to the previous page
 * @property {array} data.regions - List of regions to display as collapsibles
 * @property {string} data.regions.name - Translated region name
 * @property {array} data.regions.countries - Array of countries belonging to the current region context
 * @property {string} data.regions.countries.name - Short code for the current country, paired with subsequent language codes to generate the label key and locale link
 * @property {array} data.regions.countries.languages - Array of strings for available languages within the given country context
 *
 * @example
 * return (
 *   <ChangeCountry name={name} data={data} />
 * )
 */

const ChangeCountry = (props = {}) => {
    const { data = {}, name } = props;
    const { target = '' } = data;

    const [loaded, setLoaded] = useState(false);
    const [regions, setRegions] = useState(data.regions);

    const title = miniGazelle.label('regions', true);

    const dataLayerEvent = ({ position, open }) => {
        dataLayer.triggerEvent('block_expanded', {
            id: 'change-country',
            name,
            position,
            open,
        });
    };

    const expandOrCollapse = expand => {
        let rgs = clone(regions);

        map(rgs, region => {
            region.open = expand;
        });

        setRegions(rgs);
    };

    useEffect(() => {
        regions.map(r => {
            r.open = false;
        });

        setLoaded(true);
    }, []);

    const onOpen = (region, i) => {
        let rgs = regions;
        rgs[i].open = true;
        setRegions(rgs.slice(0));

        dataLayerEvent({ position: i, open: true });
    };

    const onClose = (region, i) => {
        let rgs = regions;
        rgs[i].open = false;
        setRegions(rgs.slice(0));

        dataLayerEvent({ position: i, open: false });
    };

    const expandCollapseConfig = {
        logicalName: data.logicalName,
        expandText: miniGazelle.label('expandText', true),
        collapseText: miniGazelle.label('collapseText', true),
        handleButtonClick: expandOrCollapse,
    };

    const handleOnOpen = useCallback(
        (region, i) => () => {
            onOpen(region, i);
        },
        [onOpen]
    );

    const handleOnClose = useCallback(
        (region, i) => () => {
            onClose(region, i);
        },
        [onClose]
    );

    return (
        <div className="container-fluid module change-country" id="change-country">
            <div className="row">
                <div className="container change-country-wrapper">
                    <div className="tw-px-4 md:tw-flex tw-hidden tw-justify-end tw-items-center md:tw--mx-4">
                        <ExpandCollapseButtons {...expandCollapseConfig} />
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <h1 className="tw-mb-10">{title}</h1>
                        </div>
                        <div className="col-md-9">
                            <div className="panel-group">
                                {loaded &&
                                    regions.map((region = {}, i) => {
                                        const { name, countries = [], open } = region;
                                        const key = name + (open ? 'true' : 'false');

                                        return (
                                            <Collapsible
                                                key={key}
                                                data-key={key}
                                                trigger={name}
                                                open={open}
                                                className="panel panel-default"
                                                openedClassName="panel panel-default"
                                                onOpen={handleOnOpen(region, i)}
                                                onClose={handleOnClose(region, i)}
                                                triggerElementProps={{
                                                    id: `collapsible-trigger-${i}`,
                                                }}
                                                contentElementId={`collapsible-content-${i}`}
                                            >
                                                <div className="panel-collapse">
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-md-12 panel-content">
                                                                <div className="row country-language-list">
                                                                    {countries &&
                                                                        countries.map((country = {}) => {
                                                                            const { name, languages = [] } = country;

                                                                            return languages.map((language = '') => {
                                                                                const countryCode = `${language}_${name}`;
                                                                                const link = `/${countryCode}${target}`;

                                                                                return (
                                                                                    <div
                                                                                        key={countryCode}
                                                                                        className="col-md-3"
                                                                                    >
                                                                                        <div className="country-wrapper">
                                                                                            <a
                                                                                                href={link}
                                                                                                className="block-link"
                                                                                            >
                                                                                                {miniGazelle.label(
                                                                                                    `${countryCode}_countryLabel`,
                                                                                                    true
                                                                                                )}
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            });
                                                                        })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapsible>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ChangeCountry.propTypes = {
    name: PropTypes.string.isRequired,
    data: PropTypes.shape({
        logicalName: PropTypes.string,
        target: PropTypes.string.isRequired,
        regions: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                countries: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        languages: PropTypes.arrayOf(PropTypes.string).isRequired,
                    })
                ).isRequired,
            })
        ).isRequired,
    }),
};

export default ChangeCountry;
